import * as React from "react";


export const MenuBar = () => {

    return (
        <div className="menu-bar-container">

        </div>
    )
};