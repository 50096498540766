import React from 'react';
import './App.css';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import { Content } from './content/Content';

function App() {
  return (
    <div className="app-container">
        
        <Header/>

        <Content/>

        <Footer/>
    </div>
  );
}

export default App;
