import * as React from "react";
import { MenuBar } from "../menu-bar/MenuBar";
import "./Content.css";


export const Content = () => {

    return (
        <div className="viewer-container">
          <MenuBar/>
        </div>
    )
};