import * as React from "react";
import "./Header.css";

export const Header = () => {

    return (
        <div className="header-container">
            <h1>Atlantean Acres</h1>
        </div>
    )
};