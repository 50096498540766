import * as React from "react";
import "./Footer.css";

export const Footer = () => {

    return (
        <div className="footer-container">
            <div className="footer-content"> 
            Founded 2023 
            </div>
        </div>
    )
};